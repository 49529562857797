import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './src/assets/fonts/fonts.css';

export const wrapRootElement = ({ element }) => {
  return <GoogleReCaptchaProvider reCaptchaKey="6LcWAtEZAAAAAIytrMRzovc6F6SPTpA9ie9rxEnB">{element}</GoogleReCaptchaProvider>;
};

/* eslint-disable */

(function (h, o, t, j, a, r) {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: 2065525, hjsv: 6 };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
